import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from 'src/environments/config.url';

@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {

  public header: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient) { }

  getDocumentDetails(data): Observable<any> {
    let url = URLS.GetDocumentDetailsAPI;
    return this.http.post<any>(url, data, { headers: this.header, observe: 'response' }).pipe(
      map((res: any) => {
        if (res.headers && res.status == 200) {
          return res.body;
        }
      })
    );
  }

  validateOrReject(data: any): Observable<any> {
    let url = URLS.GetValidateOrRejectAPI;
    return this.http.post(url, data, { headers: this.header, responseType: 'text' }).pipe(
      map((res: any) => {
        return res.body;
      })
    );

  }
}
