import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as carpet from 'crypto-js';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IMAGES } from 'src/environments/config.all';
import { URLS } from 'src/environments/config.url';
export const skipTokenHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root'
})
export class DevLoginService {
  public token: string;
  public storedToken: any;
  public user: any;
  private secret: string;
  private header: HttpHeaders = new HttpHeaders();
  public apps: any;

  constructor(private http: HttpClient, private localStorage: LocalStorage) { 
    this.header = this.header.set('Content-Type', 'application/json');
    this.secret = "Go to Control Panel – User Accounts and turn User Account Control off. Restart the computer and run the Antioch installer. You must leave User Account Control switched off while selecting a keyboard layout for Antioch, and while making any other changes to settings, and while registering the program.";
    this.user = {
      info: {},
      permissions: {}
    };
    this.storedToken = this.getStoredToken();
  }

  validate(u, p, r = true): Observable<any> {
    let auth = { "username": u, "password": p };
    
    return this.http.post<any>(URLS.UserLoginServiceAPI, auth, { headers: this.header, observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => {
        if (res.headers.get('Authorization')) {
          this.logoutUser();
          this.token = res.headers.get('Authorization').split(' ')[1];
          this.localStorage.setItem('token', this.token).subscribe(() => { });
        } else {
          /*this.token = this.extractData(res).token;*/
        }
        return this.token;
      }));
  }

  getUserInfoPermission() {
    this.header = this.header.set("Authorization", `Bearer ${this.token}`);
    return forkJoin(
      this.http.get(URLS.UserInfoServiceAPI, { headers: this.header }).pipe(
        map((res) => {
          this.user.info = res;
          return this.user.info;
        })
      ),
      this.http.get(URLS.UserPermissionAPI, { headers: this.header }).pipe(
        map((res: any) => {
          this.user.permissions = res;
          // res.forEach(element => {
          //   this.updateAppMeta(element);
          // });
          return this.user.permissions;
        })
      ),
      (info, perm) => {
        if (info !== undefined && perm !== undefined) {
          this.storeAuth();
          return true;
        } else {
          return false;
        }
      });
  }

  updateAppMeta(ele) {
    switch (ele.applicationCode) {
      case "BUYER":
        ele.IconLink = IMAGES.BUYER_APP_ICON;
        ele.appLink = URLS.BUYER_APPLINK;
        break;
      case "SUPPLIER":
        ele.IconLink = IMAGES.SUPPLIER_APP_ICON;
        ele.appLink = URLS.SUPPLIER_APPLINK;
        break;
      case "CARRIER":
        ele.IconLink = IMAGES.CARRIER_APP_ICON;
        ele.appLink = URLS.CARRIER_APPLINK;
        break;
      case "TRUST":
        ele.IconLink = IMAGES.TRUST_APP_ICON;
        ele.appLink = URLS.TRUST_APPLINK;
      default:
        ele.IconLink = URLS.BUYER_APPLINK;
        break;
    }
  }

  updateDatafromStorage(): Promise<any> {
    let p = new Promise((resolve, reject) => {
      this.localStorage.getItem('token').subscribe((token: any) => {
        if (token !== undefined && token !== null) {
          this.token = token;
          this.loadAuthFromStorage();
          resolve(this.token);
        } else {
          reject();
        }
      }, () => { });
    });
    return p;
  }

  loadAuthFromStorage(): Promise<any> {
    let p = new Promise((resolve, reject) => {
      this.localStorage.getItem('_u').subscribe((ufo) => {
        if (ufo !== undefined && ufo != null) {
          let stufo = carpet.AES.decrypt(ufo, this.secret);
          this.user = JSON.parse(stufo.toString(carpet.enc.Utf8));
          resolve(this.user);
        } else {
          reject('nodatainstorage');
        }
      });
    });
    return p;
  }

  checkUserAuthValidity(chkTokenValidity = true): Observable<any> {
    if (this.user !== undefined && this.user.info.id !== undefined && this.token !== undefined) {
      // below paramet is if we need to check validity, not required if recent service call had worked
      if (chkTokenValidity) {
        // check if token is still valid
        return this.http.get(URLS.UserInfoServiceAPI, { headers: this.header }).pipe(
          map((user: any) => {
            if (user.id !== undefined) {
              return true;
            } else {
              return false;
            }
          },
            catchError((error) => {
              return throwError(false)
            }))
        )
      } else {
        return of(true);
      }
    }
    else {
      return of(true);
    }
  }

  checkLoginStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadAuthFromStorage().then(user => {
        this.getTokenFromStorage().subscribe((token) => {
          this.checkUserAuthValidity().subscribe((res) => {
            if (this.user.info.id == undefined) {
              this.user = user;
            }
            if (this.token == undefined) {
              this.token = token;
            }
            resolve(true)
          }, (err) => { console.log('e101'); reject(err) });
        }, (err) => { console.log('e102'); reject(err) });
      }, (err) => { console.log('e103'); reject(err) })
    })
  }


  updateUserInfo(): Observable<any> {
    this.header = this.header.set("Authorization", `Bearer ${this.token}`);
    return this.http.get(URLS.UserInfoServiceAPI, { headers: this.header }).pipe(
      map((res) => {
        this.user.info = res;
        return res;
      })
    );
  }

  updateUserPermissions(): Observable<any> {
    this.header = this.header.set("Authorization", `Bearer ${this.token}`);
    return this.http.get(URLS.UserPermissionAPI, { headers: this.header }).pipe(
      map((res) => {
        this.user.permissions = res;
        return this.user.permissions;
      })
    );
  }

  logoutUser() {
    this.token = '';
    this.user = {
      info: null,
      permissions: null
    };
    this.localStorage.clear().subscribe(() => {


    });
    // this.clearAuth();
  }

  getToken() {
    if (this.token !== undefined && this.token !== null) {
      return this.token;
    }
    else {
      return false;
    }
  }

  getUserInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.user !== undefined && this.user.info !== undefined) {
        resolve(this.user);
      } else {
        this.loadAuthFromStorage().then(() => {
          resolve(this.user);
        }, () => { reject(false) });
      }
    });


  }

  storeAuth() {
    let _u = carpet.AES.encrypt(JSON.stringify(this.user), this.secret).toString();
    this.localStorage.setItem('token', this.token).subscribe(res => { });
    this.localStorage.setItem('_u', _u).subscribe(res => { });

    //TEST compatibility with other apps
    window.sessionStorage.setItem('jwtToken', this.token);
    window.sessionStorage.setItem('currentUser', JSON.stringify(this.user));
    window.sessionStorage.setItem('currency', this.user.info.currency);

  }



  // clearAuth() {
  //   this.token = '';
  //   this.user = {
  //     info: null,
  //     permissions: null
  //   };
  //   this.localStorage.clear().subscribe(()=>{
  //   });
  // }

  //don't need this.. because wer are doing this call it above in getUserInfoPermission
  // getSubscribedApplications(): Observable<any> {
  //   this.header = this.header.set("Authorization", `Bearer ${this.token}`);
  //   return this.http.get(URLS.GetSubscribedApplicationsAPI, { headers: this.header }).pipe(
  //     map((res) => {
  //       return res;
  //       // return this.extractData(res);
  //     }));
  // }



  getTokenFromStorage(): Observable<any> {
    return this.localStorage.getItem('token');
  }

  getStoredToken() {
    this.localStorage.getItem('token').subscribe((tkn) => {
      if (tkn != undefined && tkn != null) {
        this.storedToken = tkn;
      }
    });
    return this.storedToken;
  }

  isUserValid(): Observable<any> {
    return this.updateUserInfo().pipe(
      map((res) => {
        if (res !== undefined) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

}
