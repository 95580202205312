import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AppStorageService } from './services/app-storage.service';
import { OneAuthService } from 'one-auth';
import { InitLoaderService } from './services/init-loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private page: string;
  public isLogin: boolean = false;
  public isAppSelector = false;
  constructor(private auth: OneAuthService,public init:InitLoaderService, public translate: TranslateService, public router: ActivatedRoute, private appStorage: AppStorageService, public route: Router){
    this.translate.setDefaultLang('en_US');
    this.init.load();
  }

  ngOnInit() {
    if (this.auth.user.info.id === undefined) {
      this.auth.LoadAuth().then(() => {
        this.appStorage.loadMeta();
      });
    }

    this.route.events.subscribe((pat) => {
      if (this.route.url === "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.route.url === "/logout") {
        if(!this.isLogin){
          this.isLogin = true;
        }
      } else if(this.route.url !== "/BBF65054CC9C2C8CB6692B2A1648FD8C" && this.route.url !== "/logout") {
        if(this.isLogin){
          this.isLogin = false;
        }
      }
      //(PP) PMB-7161 Added condition to hide header menu when connect app open from portal app
      let ss = window.sessionStorage.getItem('ExternalOpen');
      if (!ss) {
        if (this.route.url.includes('tab=true')) {
          this.init.showHeaderMenu = this.route.url.includes('tab=true') ? false : true;
          if (!this.init.showHeaderMenu) {
            window.sessionStorage.setItem("ExternalOpen", this.init.showHeaderMenu.toString());
          }
        }
      } else if (ss != null) {
        this.init.showHeaderMenu = false;
      }
    });
  }
}
