import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DevLoginComponent } from './modules/dev-login/components/dev-login/dev-login.component';
import { RedirectGuard } from './services/redirect-guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
    data: { 
      title: "Commodity Pricing" 
    }
  },
  {
    path: 'industry-analytics',
    loadChildren: () => import('./modules/industry-analytics/industry-analytics.module').then(m => m.IndustryAnalyticsModule)
  },
  {
    path: 'login',
    component: RedirectGuard,
    canActivate: [RedirectGuard],
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/login"
    }
  },
  {
    path: 'logout',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/logout"
    }
  },
  {
    path: 'apps',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/apps"
    }
  },
  {
    path: "BBF65054CC9C2C8CB6692B2A1648FD8C",
    component: DevLoginComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
