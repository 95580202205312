import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentInfobarComponent } from './components/content-infobar/content-infobar.component';
import { SideToolbarComponent } from './components/side-toolbar/side-toolbar.component';
import { HeaderToolbarComponent } from './components/header-toolbar/header-toolbar.component';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { OneToolbarModule } from 'one-auth';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    HeaderToolbarComponent,
    SideToolbarComponent,
    ContentInfobarComponent
  ],
  imports: [
    CommonModule,
    MaterialUiModule,
    OneToolbarModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule
  ],
  exports: [
    HeaderToolbarComponent,
    SideToolbarComponent,
    ContentInfobarComponent
  ]
})
export class AppToolbarsModule { }
