import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { ProcurantConfirmDialogComponent } from '../components/procurant-confirm-dialog/procurant-confirm-dialog.component';
import { ALERT_MSG_PARAM, ALERT_TYPE } from '../constant';
// import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
// import { CommonDialogComponent } from './common-dialog/common-dialog.component';

@Injectable()
export class DialogBoxService {

  dialogRef: MatDialogRef<any>;
  data: any;
  isSubmited: boolean = false;

  constructor(public dialog: MatDialog) { }

  // Confirmation dialog box
  openConfirmDialog(message, buttonOkText, buttonCancelText, width?, height?, hideCancelBtn = false, title = '') {
    return new Promise((resolve, reject) => {
      buttonOkText = buttonOkText || 'BUTTON.YES';
      buttonCancelText = buttonCancelText || 'BUTTON.NO';
      let dialogWidth: string = '250px';
      let dialogHeight: string = '250px';

      if (width)
        dialogWidth = width.toString() + 'px';
      if (height)
        dialogHeight = height.toString() + 'px';

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: dialogWidth,
        height: dialogHeight,
        data: { message: message, buttonOkText: buttonOkText, buttonCancelText: buttonCancelText, hideCancel: hideCancelBtn, title: title },
        disableClose: true //can not close dialog on escape button
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.result === true) {
            resolve(true);
          }
          else { reject(); }
        }
        else { reject(); }
      });
    });
  }


  // To open any component in dialog box
  // openCommonDialog(component, title, rawData?, width?, height?)/* : Promise<boolean> */ {
  //   return this.dialogRef = this.dialog.open(CommonDialogComponent, {
  //     width: width,
  //     height: height,
  //     data: { component: component, title: title, dataDetails: rawData || undefined },
  //     disableClose: true //can not close dialog on escape button
  //   });
  // }

  closeDialog(cloaseData?) {
    this.dialogRef.close(cloaseData);
  }

  submited() {
    this.isSubmited = true;
  }

  ProcurantConfirmDialog(param: ALERT_MSG_PARAM) {
    return new Promise((resolve, reject) => {
      param.btnOKText = param.btnOKText || 'BUTTON.YES';
      param.btnCancelText = param.btnCancelText || 'BUTTON.NO';
      param.hideCancelBtn = param.hideCancelBtn || false;
      param.width = param.width || '550px';
      param.height = param.height || 'auto';
      param.additionalMessage = param.additionalMessage || '';
      param.headingMessage = param.headingMessage || '';
      param.icon = param.icon || ALERT_TYPE.INFO;
      param.disableClose = param.disableClose || true;
      // let dialogWidth: string = '550px';
      // let dialogHeight: string = 'auto';
      if (param.width) {
        param.width = param.width.toString() + 'px';
      }
      if (param.height) {
        param.height = param.height.toString() + 'px';
      }
      const dialogRef = this.dialog.open(ProcurantConfirmDialogComponent, {
        width: param.width,
        height: param.height,
        data: { headingMessage: param.headingMessage, message: param.message, buttonOkText: param.btnOKText, buttonCancelText: param.btnCancelText, additionalText: param.additionalMessage, hideCancel: param.hideCancelBtn, icon: param.icon },
        disableClose: param.disableClose //can not close dialog on escape button
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.result === true) {
            resolve(true);
          }
          else { reject(); }
        }
        else { reject(); }
      });
    });
  }

}
