<app-toolbar [showAppSwitch]="init?.showHeaderMenu">
  <div class="logo" logo><img src="{{IMAGES.PROCURANT_LOGO}}"></div>
  <div product-name>
    {{ 'APP_META.CONNECT' | translate }}
  </div>
  <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
  <!-- <span userimage><img [src]="localUrl ? localUrl : 'assets/images/profile.png'" router="UserProfileModule" alt="" title="" /></span>
  <span username matTooltip="{{user?.info?.firstName}} {{user?.info?.lastName}}">
    {{user?.info?.firstName}} {{user?.info?.lastName}}
  </span> -->
  <span userimage>
    <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
    <div *ngIf="!auth?.thumbnil" class="img-avtar">
      <span>{{avtarName}}</span>
    </div>
  </span>
  <!-- single column -->
  <!-- <div username class="user-profile">
    <div>
      <img [src]="auth?.thumbnil ? auth?.thumbnil : 'assets/images/profile.png'" router="UserProfileModule" alt=""
        title="" class="img">
    </div>
    <div>
      <span class="user-name">
        {{user?.info?.firstName}} {{user?.info?.lastName}}
      </span>
    </div>
  </div> -->

  <!-- double column -->
  <div username class="double-user-profile">
    <div>
      <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
      <div *ngIf="!auth?.thumbnil" class="img-avtar">
        <span>{{avtarName}}</span>
      </div>
    </div>
    <div>
      <span class="user-name">
        {{user?.info?.firstName}} {{user?.info?.lastName}}
      </span>
    </div>
  </div>
  <div menu-options>
    <button mat-menu-item (click)="logout()">
      {{ 'BUTTON.LOGOUT' | translate }}
    </button>
  </div>
</app-toolbar>