export const SESSION = Object.freeze({
    WEATHER_REPORT_LIST: "weather-report-list"
});

// Added for New Design of confirmation dialog
export enum ALERT_TYPE {
	SUCCESS = 1,
	WARNING,
	ERROR,
	INFO
}

// Added for New Design of confirmation dialog
export interface ALERT_MSG_PARAM {
	headingMessage?: String,
	message: any;
	additionalMessage?: String;
	btnOKText: String;
	btnCancelText: String;
	width?: any;
	height?: any;
	hideCancelBtn?: boolean;
	icon?: ALERT_TYPE;
	disableClose?: boolean;
	title?: String;
}