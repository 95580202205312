import { Component, Inject, OnInit, Sanitizer } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ALERT_TYPE } from '../../constant';
@Component({
  selector: 'procurant-confirm-dialog',
  templateUrl: './procurant-confirm-dialog.component.html',
  styleUrls: ['./procurant-confirm-dialog.component.scss']
})
export class ProcurantConfirmDialogComponent implements OnInit {

  hideCancel: boolean = false;
  icon: ALERT_TYPE
  public alt_type = ALERT_TYPE
  
  constructor(public dialogRef: MatDialogRef<ProcurantConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: Sanitizer) { }

  ngOnInit() {
    this.replace();
  }

  onOkClick() {
    return new Promise((resolve, reject) => {
      this.dialogRef.close({ result: true });
      resolve(true);
    });
  }

  replace() {
    let regex = /<(.+?)>/g;
    let replacetext1 = this.data.message.match(regex);
    if (replacetext1) {
      replacetext1.forEach((element) => {
        this.data.message = this.data.message.replace(
          element,
          element.includes('/') ? "</span>" : "<span class='dark'>"
        );
      });
    }
    //this.sanitizer.sanitize(SecurityContext.HTML, this.data.message);
    //return str;
  }

  onCancelClick() {
    return new Promise((resolve, reject) => {
      this.dialogRef.close({ result: false });
      resolve(false);
    });
  }


}
