import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDialogComponent } from './components/common-dialog/common-dialog.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
// import { CommonNotificationsComponent } from './components/common-notifications/common-notifications.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';
// import { AppStorageService } from '../../services/app-storage.service';
// import { AppAuthService } from './services/temp-auth.service';
// import { HttpRequestInterceptor } from '../../services/http-interceptor.service';
import { ProcurantLoadingComponent } from './components/procurant-loading/procurant-loading.component';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DialogBoxService } from './services/dialog-box.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonServicesService } from './services/common-services.service';
import { ApolloModule } from 'apollo-angular';
import { ProcurantConfirmDialogComponent } from './components/procurant-confirm-dialog/procurant-confirm-dialog.component';


@NgModule({
    declarations: [
        CommonDialogComponent,
        CommonSnackbarComponent,
        CommonNotificationComponent,
        ProcurantLoadingComponent,
        ConfirmDialogComponent,
        ProcurantConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialUiModule,
        TranslateModule,
        ApolloModule,
    ],
    providers: [
        DialogBoxService,
        CommonServicesService
    ],
    exports: [
        CommonDialogComponent,
        ProcurantLoadingComponent,
        ApolloModule,
        ProcurantConfirmDialogComponent
    ]
})
export class SharedComponentsModule { }
