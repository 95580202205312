import { environment } from './environment';

export const URLS = Object();

URLS.UserLoginServiceAPI = environment.BASE_API_URL + "login";
URLS.UserLogoutServiceAPI = environment.BASE_API_URL + "api/auth/logout";
URLS.UserInfoServiceAPI = environment.BASE_API_URL + "auth/api/user/info";
URLS.UserPermissionAPI = environment.BASE_API_URL + "auth/api/user/user-app-module-permissions";

URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-ApplicationModules";

URLS.CountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.StateListAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";

// User pinned list maintain
URLS.GetFilterListAPI = environment.BASE_API_URL + "admin/api/user-setting/get/filters/";
URLS.SaveFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/save/filter";
URLS.DeleteFilterAPI = environment.BASE_API_URL + "admin/api/user-setting/delete/filter";

//get weather-reporting 
URLS.WeatherReporting = environment.BASE_API_URL + "datacollector/connect/api/fetch-weatherfeed";

//AG tools commodities data
URLS.AgToolsCommodities = environment.BASE_API_URL + "admin/ag/get-access";