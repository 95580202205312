import { Component, OnInit } from '@angular/core';
import { IMAGES } from '../../../../../environments/config.all';
import { AppStorageService } from 'src/app/services/app-storage.service';
// import { OneAuthService } from 'src/app/services/one-auth.service';
import { OneAuthService } from 'one-auth';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { InitLoaderService } from 'src/app/services/init-loader.service';
// import { AppToolbarComponent } from '../../../app-toolbar/app-toolbar/app-toolbar.component';

@Component({
  selector: 'header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {
  public IMAGES = IMAGES;
  public user;
  public subApps;
  localUrl: any;
  editIcon: any;
  avtarName: any;
  constructor(public auth: OneAuthService, private router: Router, public init: InitLoaderService) { }

  ngOnInit() {
    this.user = this.auth.user;
    this.subApps = this.auth.user.subApps;
    this.setUserImage();
    // (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce
    let name = this.user.info.firstName.split(" ")[0] +' '+this.user.info.lastName.split(" ")[0];
    this.avtarName = name.split(" ").map((n)=>n[0]).join("").toUpperCase();
  }

  setUserImage() {
    if (this.auth.user.info && this.auth.user.info.fileName != null && this.auth.user.info.thumbnail != null) {
      this.localUrl = "data:image/" + this.auth.user.info.fileName.split('.').pop() + ";base64," + this.auth.user.info.thumbnail;
    }
  }
  openAdmin() {
    //window.location.href = environment.BASE_API_URL + "common-app/#/app/company/account-setting"
  }

  logout() {
    this.auth.logoutUser().subscribe(res => {
      // sessionStorage.clear();
      if (res.status === 200) {
        this.auth.clearSession();
        this.router.navigate(['logout']);
      }
    });
  }

}
