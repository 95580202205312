export const IMAGES = Object();

IMAGES.PROCURANT_LOGO = "assets/images/procurant-logo.png";
IMAGES.ETHEREUM_LOGO = "assets/images/ethereumlogo.png";
IMAGES.LOADING_ICON = "assets/images/procurant_logo.gif";



IMAGES.BUYER_APP_ICON = "assets/images/buyer.png";
IMAGES.SUPPLIER_APP_ICON = "assets/images/carrier.png";
IMAGES.CARRIER_APP_ICON = "assets/images/supplier.png";
IMAGES.TRUST_APP_ICON = "assets/images/trust.png";
