import { Component, OnInit } from '@angular/core';
import { IMAGES } from 'src/environments/config.all';

@Component({
  selector: 'procurant-loading',
  templateUrl: './procurant-loading.component.html',
  styleUrls: ['./procurant-loading.component.scss']
})
export class ProcurantLoadingComponent implements OnInit {

  public IMAGES = IMAGES;
  constructor() { }

  ngOnInit() {
    
  }

}
