import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialUiModule } from './modules/material-ui/material-ui.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { AppToolbarsModule } from './modules/app-toolbars/app-toolbars.module';
import { MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { HttpRequestInterceptor } from './services/http-interceptor.service';
import { AppStorageService } from './services/app-storage.service';
// import { OneAuthService } from './services/one-auth.service';
import { OneAuthService, OneAuthModule } from 'one-auth';
// import {  } from 'one-auth';
import { ActivatedRouteSnapshot, RouterStateSnapshot, RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './services/redirect-guard';
import { environment } from 'src/environments/environment';
import { DevLoginModule } from './modules/dev-login/dev-login.module';
import { InitLoaderService } from './services/init-loader.service';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { CookieService } from 'ngx-cookie-service';
import { LoadingService } from './modules/shared-components/services/loading.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OneAuthModule.forRoot(environment),
    HttpClientModule,
    MaterialUiModule,
    AppRoutingModule,
    AppToolbarsModule,
    GridModule,
    SharedComponentsModule,
    DevLoginModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // LocalStorageModule.forRoot({ IDBNoWrap: true })
  ],
  providers: [
    AppStorageService,
    OneAuthService,
    InitLoaderService,
    CookieService,
    HttpRequestInterceptor,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    LoadingService,
    RedirectGuard,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.BASE_API_URL + "datacollector/connect/api/query"

            // uri: 'http://localhost:9393/connect/api/query/graphql'


          }),
        };
      },
      deps: [HttpLink]
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}