<div mat-dialog-container class="container red">
    <div class="overlay-container">
      <!-- <div mat-dialog-title></div> -->
      <div mat-dialog-content>
        <div class="main-content">
          <div class="gif-file">
            <img *ngIf="data?.icon==alt_type.ERROR" src="../../../../assets/images/Error.gif" />
            <img *ngIf="data?.icon==alt_type.SUCCESS" src="../../../../assets/images/Success.gif" />
            <img *ngIf="data?.icon==alt_type.WARNING" src="../../../../assets/images/warning.png" />
            <img *ngIf="data?.icon==alt_type.INFO" src="../../../../assets/images/Neutral.gif" />
          </div>
          <div class="center-container">
            <div class="heading">{{data?.headingMessage}}</div>
            <div id="contentData" class="contentData">
              <span [innerHTML]="data?.message"></span>
            </div>
            <div class="light">{{data?.additionalText}}</div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions class="footer-action">
        <button mat-raised-button  *ngIf="!data.hideCancel" class="custom-btn-color-1" (click)="onCancelClick()">{{data?.buttonCancelText}}</button>
        <button mat-raised-button class="custom-btn-color-2" (click)="onOkClick()"
          cdkFocusInitial>{{data?.buttonOkText}}</button>
      </div>
    </div>
  </div>