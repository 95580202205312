import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  public categories: any;
  public user: any;

  constructor(private localStorage: LocalStorage) { }

  loadMeta(){

  }
}
