<div class="diag-header">
<h2 mat-dialog-title>{{msg.title}}</h2>
  <!-- <div class="closex">X</div> -->
</div>
<mat-dialog-content>
  <div class="dialog-message">
    {{msg.error}}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-stoked-button (click)="closeDialog()">Close</button>
</mat-dialog-actions>