import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OneAuthService, OneTrackerService } from "one-auth";

@Injectable({
  providedIn: 'root'
})
export class InitLoaderService {
  showHeaderMenu = true;
  constructor(private auth: OneAuthService, private t: OneTrackerService, private route: Router){

  }
  load(){
      this.auth.LoadInitInfo();
  }
}
