import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'common-notification',
  templateUrl: './common-notification.component.html',
  styleUrls: ['./common-notification.component.scss']
})
export class CommonNotificationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
